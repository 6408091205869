@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap')

body
    background: linear-gradient(90deg, #C5E384, #558B2F)
    display: flex
    justify-content: center
    font-family: 'Roboto', sans-serif
    height: 100vh
    margin: 0
    padding: 0


main
    background-color: #FFFFFF
    padding: 20px
    border-radius: 8px
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2)
    width: 740px
    box-sizing: border-box
    margin: 20px 0
    min-height: 100vh
    display: flex
    flex-direction: column

    .button
      display: inline-block
      background: linear-gradient(135deg, #a05bcb, #4b69fd)
      color: #ffffff
      padding: 2px 10px
      border-radius: 8px
      text-align: center
      text-decoration: none
      font-size: 1em
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15)
      transition: transform 0.3s ease, box-shadow 0.3s ease
      cursor: pointer

      &.api
        width: 128px
        padding: 8px 16px
        display: flex
        justify-content: center
        align-items: center
        height: 4rem

      &:hover
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5)

    #rinav .button
      margin: 0 .3em

    div.container
      display: flex
      gap: 20px
      width: 100%

      .column
        flex: 1
        display: flex
        flex-direction: column
        gap: 20px

        div.panel
          background-color: #c9e9d3
          color: #163310
          border: solid #93ac9b 2px
          padding: 20px
          border-radius: 8px

          h2
            margin: 0
            padding: 0 0 .4em 1em
            font-size: 1.2rem

          h3
            margin: 0
            padding: 0 0 .2em 0
            font-size: 1.1rem

          div.line
            display: flex
            flex-direction: row
            align-items: center
            margin-bottom: .1rem

          span.label
            font-size: 1.1rem
            font-weight: 700
            margin-right: .3rem

          span.value
            font-size: 1rem

.fade-out-div 
  transition: opacity 0.5s ease-out
  opacity: 1


.fade-out 
  opacity: 0

#wp_content
    width: 100%
    overflow: hidden
    .wp-block-image
      img
        max-width: 100%
        height: auto
        display: block



@media (max-width: 600px)



@media screen and (max-width: 768px) 
  main
    padding: 15px
    width: 100%

    .container 
        flex-direction: column
        
        .column 
            width: 100%
    
